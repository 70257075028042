import React from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import SectionHeader from './partials/TestimoniosHeader';
import Image from '../elements/Image';

//import dependencies
import ConstantHelper from '../../global/constant_helper'

import '../css/who_we_are.css'

const propTypes = {
  ...SectionTilesProps.types
}

const defaultProps = {
  ...SectionTilesProps.defaults
}
const FeaturesTiles = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {

  const outerClasses = classNames(
    'features-tiles section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-tiles-inner section-inner pt-0',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const tilesClasses = classNames(
    'tiles-wrap center-content',
    pushLeft && 'push-left'
  );

  const sectionHeader = {
    title1: 'Los',
    title2: ' testimonios ',
    title3: 'de nuestros clientes y candidatos',
    paragraph: 'ratifican nuestros valores y nuestra auténtica personalidad.'
  };

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
        <SectionHeader data={sectionHeader} className="center-content" />

          <div className={tilesClasses}>

            <div className="tiles-item reveal-from-bottom">
              <div className="tiles-item-inner valores-container testimonio-container">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      className="quote-image"
                      src={require('./../../assets/images/icon_quote_2x.png')}
                      alt="Features tile icon 01"
                      width={64}
                      height={64} />
                  </div>
                </div>
                <div>
                  <p className="m-0 text-sm testimonio-desc content-text">
                    {ConstantHelper.testimonio_desc_1}
                  </p>
                  <p className="m-0 text-sm testimonio-name">
                    {ConstantHelper.testimonio_name_1}
                  </p>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom">
              <div className="tiles-item-inner valores-container testimonio-container">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      className="quote-image"
                      src={require('./../../assets/images/icon_quote_2x.png')}
                      alt="Features tile icon 01"
                      width={64}
                      height={64} />
                  </div>
                </div>
                <div>
                  <p className="m-0 text-sm testimonio-desc content-text">
                    {ConstantHelper.testimonio_desc_2}
                  </p>
                  <p className="m-0 text-sm testimonio-name">
                    {ConstantHelper.testimonio_name_2}
                  </p>
                </div>
              </div>
            </div>
            
            <div className="tiles-item reveal-from-bottom">
              <div className="tiles-item-inner valores-container testimonio-container">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      className="quote-image"
                      src={require('./../../assets/images/icon_quote_2x.png')}
                      alt="Features tile icon 01"
                      width={64}
                      height={64} />
                  </div>
                </div>
                <div>
                  <p className="m-0 text-sm testimonio-desc content-text">
                    {ConstantHelper.testimonio_desc_3}
                  </p>
                  <p className="m-0 text-sm testimonio-name">
                    {ConstantHelper.testimonio_name_3}
                  </p>
                </div>
              </div>
            </div>
            
            <div className="tiles-item reveal-from-bottom">
              <div className="tiles-item-inner valores-container testimonio-container">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      className="quote-image"
                      src={require('./../../assets/images/icon_quote_2x.png')}
                      alt="Features tile icon 01"
                      width={64}
                      height={64} />
                  </div>
                </div>
                <div>
                  <p className="m-0 text-sm testimonio-desc content-text">
                    {ConstantHelper.testimonio_desc_4}
                  </p>
                  <p className="m-0 text-sm testimonio-name">
                    {ConstantHelper.testimonio_name_4}
                  </p>
                </div>
              </div>
            </div>
            
            <div className="tiles-item reveal-from-bottom">
              <div className="tiles-item-inner valores-container testimonio-container">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      className="quote-image"
                      src={require('./../../assets/images/icon_quote_2x.png')}
                      alt="Features tile icon 01"
                      width={64}
                      height={64} />
                  </div>
                </div>
                <div>
                  <p className="m-0 text-sm testimonio-desc content-text">
                    {ConstantHelper.testimonio_desc_5}
                  </p>
                  <p className="m-0 text-sm testimonio-name">
                    {ConstantHelper.testimonio_name_5}
                  </p>
                </div>
              </div>
            </div>
            
            <div className="tiles-item reveal-from-bottom">
              <div className="tiles-item-inner valores-container testimonio-container">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      className="quote-image"
                      src={require('./../../assets/images/icon_quote_2x.png')}
                      alt="Features tile icon 01"
                      width={64}
                      height={64} />
                  </div>
                </div>
                <div>
                  <p className="m-0 text-sm testimonio-desc content-text">
                    {ConstantHelper.testimonio_desc_6}
                  </p>
                  <p className="m-0 text-sm testimonio-name">
                    {ConstantHelper.testimonio_name_6}
                  </p>
                </div>
              </div>
            </div>
            
          </div>
        </div>
      </div>
    </section>
  );
}

FeaturesTiles.propTypes = propTypes;
FeaturesTiles.defaultProps = defaultProps;

export default FeaturesTiles;