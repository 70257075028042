import React from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';

//import dependencies
import ConstantHelper from '../../global/constant_helper'

import '../css/who_we_are.css'

const propTypes = {
  ...SectionTilesProps.types
}

const defaultProps = {
  ...SectionTilesProps.defaults
}
const FeaturesTiles = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {

  const outerClasses = classNames(
    'features-tiles section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-tiles-inner section-inner pt-0',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const tilesClasses = classNames(
    'tiles-wrap center-content',
    pushLeft && 'push-left'
  );

  const sectionHeader = {
    title: 'Nuestro equipo se desempeña acorde',
    subtitle: 'a los valores TRISKELL',
    paragraph: 'y se reflejan en cada servicio y proyecto que realizamos'
  };

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
        <SectionHeader data={sectionHeader} className="center-content" />

          <div className={tilesClasses}>

            <div className="tiles-item reveal-from-bottom">
              <div className="tiles-item-inner valores-container">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16 valores-image">
                    <Image
                      src={require('./../../assets/images/icon_comunicacion_2x.png')}
                      alt="Features tile icon 01"
                      width={64}
                      height={64} />
                  </div>
                  <p className="valores-title">{ConstantHelper.valores_title_1}</p>
                  <Image
                      className="line-valores"
                      src={require('./../../assets/images/line_valores_2x.png')}
                      alt="Features tile icon 02"
                      width={32}
                      height={1} />
                </div>
                <div className="valores-tiles-item-content ">
                  <p className="m-0 text-sm content-text">
                    {ConstantHelper.valores_content_1}
                  </p>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom" data-reveal-delay="200">
              <div className="valores-container">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16 valores-image">
                    <Image
                      src={require('./../../assets/images/icon_efectividad_2x.png')}
                      alt="Features tile icon 02"
                      width={64}
                      height={64} />
                  </div>
                  <p className="valores-title">{ConstantHelper.valores_title_2}</p>
                  <Image
                      className="line-valores"
                      src={require('./../../assets/images/line_valores_2x.png')}
                      alt="Features tile icon 02"
                      width={32}
                      height={1} />
                </div>
                <div className="valores-tiles-item-content">
                  <p className="m-0 text-sm content-text">
                    {ConstantHelper.valores_content_2}
                  </p>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom" data-reveal-delay="400">
              <div className="tiles-item-inner valores-container">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16 valores-image">
                    <Image
                      src={require('./../../assets/images/icon_pasion_2x.png')}
                      alt="Features tile icon 03"
                      width={64}
                      height={64} />
                  </div>
                  <p className="valores-title">{ConstantHelper.valores_title_3}</p>
                  <Image
                      className="line-valores"
                      src={require('./../../assets/images/line_valores_2x.png')}
                      alt="Features tile icon 02"
                      width={32}
                      height={1} />
                </div>
                <div className="valores-tiles-item-content">
                  <p className="m-0 text-sm content-text">
                    {ConstantHelper.valores_content_3}
                  </p>
                </div>
              </div>
            </div>


          </div>
        </div>
      </div>
    </section>
  );
}

FeaturesTiles.propTypes = propTypes;
FeaturesTiles.defaultProps = defaultProps;

export default FeaturesTiles;