const ConstantHelper = {

    /* NAVBAR */
    link1: '¿Quiénes Somos?',
    link2: 'Valores',
    link3: 'Nosotras',
    link4: 'Servicios',
    link5: 'Alianzas',
    link6: 'Testimonios',
    link7: 'Contacto',

    /* WHO WE ARE */

    wwa_title_1: 'Contribuimos en formar ',
    wwa_title_2: 'relaciones beneficiosas',
    wwa_title_3: 'que aporten valor y desarrollo a nuestros clientes, la comunidad y TRISKELL.',
   
    wwa_card_1: 'Somos una consultora peruana renovada y reinventada, con una trayectoria de 25 años en el mercado local e internacional.',
    wwa_card_2: 'Hemos capacitado a más de 200 psicólogos sobre la metodología de competencias y hemos hecho escuela formando profesionales integrales en la gestión del talento humano.',
    wwa_card_3: 'Son 4 nuevos años de transformación e innovación constante que aportan valor a nuestros servicios.',

    /* VALORES */

    valores_title_1: 'Comunicación',
    valores_title_2: 'Efectividad',
    valores_title_3: 'Pasión',
    
    valores_content_1: ' Brindamos una atención personalizada, con un diálogo constante y cercano con nuestros grupos de interés.',
    valores_content_2: 'Trabajamos enfocados en la calidad de nuestros servicios con agilidad en los tiempos de respuesta, superando las expectativas de nuestros clientes.',
    valores_content_3: 'Hacemos nuestro trabajo con vocación, disfrutando cada proyecto.',

    /* US */

    associate: 'Socia fundadora',
    mily: 'MILAGROS GONZALES DEL VALLE CABRERA', 
    mily_desc: 'Psicóloga Organizacional Colegiada de la UNIFE con especialización en Gestión Estratégica de la Capacitación en la UP. Más de 25 años de trayectoria en Recursos Humanos. Fue Socia de Innovación Empresarial durante 20 años. Experiencia: BCP y Citibank del Perú. Diseña y facilita Talleres de Formación y Desarrollo.',
    mily_mail: 'mgonzalesdelvalle',
    email: '@triskell.com.pe',
    mafer: 'MARIA FERNANDA GONZALES DEL VALLE PUN',
    mafer_desc: 'Terapeuta Holistica de Runawasi, con Diplomado de Abordaje Sistémico y entrenamiento en Grafologia. Más 10 años de experiencia en Recursos Humanos. Líder de Operaciones en procesos clave de búsqueda de talento. Diseña y facilita Talleres de Outdoor Training y Team Buildings.',
    mafer_mail: 'mfgonzalesdelvalle',

    /* SERVICES */

    services_title_1: 'Evaluaciones psicolaborales',
    services_title_2: 'Reclutamiento y selección',
    services_title_3: 'Capacitaciones y terapia',
    services_num_1: '3233',
    services_num_1_text: 'personas evaluadas',
    services_percentage_1: '146%',
    services_percentage_1_text: 'de crecimiento en los últimos 2 años',
    services_num_2: '44%',
    services_num_2_text: 'procesos cerrados en un menor plazo del acordado',
    services_percentage_2: '11%',
    services_percentage_2_text: 'de candidatos seleccionados obtuvieron ascensos',
    services_num_3: '462',
    services_num_3_text: 'personas entrenadas en habilidades blandas',
    services_percentage_3: '199',
    services_percentage_3_text: 'profesionales formados en aprendizaje experiencial',

    /* ALIANZAS */

    alianzas_1: 'Nuestras ',
    alianzas_2: 'alianzas',
    alianzas_desc: 'Triskell en alianza con Psicoterapia en Casa - Chile, se han unido para brindar a sus clientes un nuevo servicio: "Cuidado de la Salud Mental en tiempos de crisis", que comulga con la empatía y la solidaridad empresarial por el bienestar emocional de sus colaboradores para que puedan desprenderse o aprender a manejar positivamente sus temores, preocupaciones y/o angustias por la pandemia.',

    /* TESTIMONIOS */

    testimonio_desc_5: '— La experiencia del taller y las dinámicas virtuales fueron muy beneficiosas, como punto de partida para identificar mis fortalezas y debilidades enfocadas en un contexto no solo laboral, sino también personal y de desarrollo.',
    testimonio_name_5: 'Daniel Acco',
    testimonio_desc_6: '— Durante el proceso de selección, me sentí confortable por la confianza y la calidez humana con la que fui tratada en todo momento.',
    testimonio_name_6: 'Silvia Leyton',
    testimonio_desc_4: '— TRISKELL me ayudó a conseguir un excelente puesto de trabajo en una gran empresa que me permitió crecer profesionalmente. El proceso de selección fue muy agradable, rápido y sobre todo cálido, estoy muy agradecida con la consultora',
    testimonio_name_4: 'Leslie Segura',
    testimonio_desc_3: '— Trabajo con TRISKELL desde hace 4 años, cuando arranqué mi propio emprendimiento. Me apoyaron con la contratación de todo el personal de mi startup; luego he seguido tercerizando con ellos nuestros requerimientos de personal clave para las dos empresas que hoy tengo.',
    testimonio_name_3: 'Rafael Wong – Gerente General',
    testimonio_desc_2: '— Para la bolsa de empleo de la PUCP, la colaboración de TRISKELL en los últimos años ha sido un gran aporte en la capacitación de jóvenes estudiantes para potenciar sus habilidades blandas, contribuyendo con profesionalismo y empatía en la asesoría personalizada que brindan.',
    testimonio_name_2: 'Jesús Luyo – Jefe Bolsa de Trabajo',
    testimonio_desc_1: '— En Ferreycorp estamos agradecidos por la relación construida en estos últimos 4 años con TRISKELL. Siempre están orientados a brindar una experiencia de calidad y transmitir nuestra cultura a nuestros candidatos en todo el proceso de evaluación desde el primer contacto.',
    testimonio_name_1: 'Ma. Teresa Merino – Gerente de Recursos Humanos',

}

module.exports = ConstantHelper;