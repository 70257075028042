import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Image from '../elements/Image';
import '../css/who_we_are.css'

const propTypes = {
  topOuterDivider: PropTypes.bool,
  topDivider: PropTypes.bool
}

const defaultProps = {
  topOuterDivider: false,
  topDivider: false
}

const Footer = ({
  className,
  topOuterDivider,
  topDivider,
  ...props
}) => {

  const classes = classNames(
    'site-footer center-content-mobile',
    topOuterDivider && 'has-top-divider',
    className
  );

  return (
    <footer
      {...props}
      className={classes}
    >
      <div className="container">
      <h1 className="primary-1 m-0">¿Cómo <span className="primary-5">contactarnos?</span></h1>
        <div className={
          classNames(
            'site-footer-inner',
            topDivider && 'has-top-divider'
          )}>
          <div className="footer-top space-between text-xxs">
            <div className="footer-box">
              <Image
                width={48}
                height={48} 
                src={require('./../../assets/images/icon_whatsapp_2x.png')}
                alt=""/>
                <p className="footer-text">989 282 364 | 980 680 274 </p>
            </div>
            <div className="footer-box">
              <Image
                width={48}
                height={48} 
                src={require('./../../assets/images/icon_email_2x.png')}
                alt=""/>
                <p className="footer-text">informes@triskell.com.pe</p>
            </div>
          </div>
          <div className="footer-bottom space-between text-xxs invert-order-desktop">
            <div className="footer-box">
                <Image
                  width={48}
                  height={48} 
                  src={require('./../../assets/images/icon_linkedin_2x.png')}
                  alt=""/>
                  <p className="footer-text">TRISKELL Gestión Empresarial</p>
              </div>
              <div className="footer-box">
                <Image
                  width={48}
                  height={48} 
                  src={require('./../../assets/images/icon_facebook_2x.png')}
                  alt=""/>
                  <p className="footer-text">Triskell Gestión Empresarial</p>
              </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

Footer.propTypes = propTypes;
Footer.defaultProps = defaultProps;

export default Footer;