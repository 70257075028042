import React from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import SectionHeader from './partials/ServicesHeader';
import Image from '../elements/Image';

//import dependencies
import ConstantHelper from '../../global/constant_helper'

import '../css/who_we_are.css'

const propTypes = {
  ...SectionTilesProps.types
}

const defaultProps = {
  ...SectionTilesProps.defaults
}
const FeaturesTiles = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {

  const outerClasses = classNames(
    'features-tiles section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-tiles-inner section-inner pt-0',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const tilesClasses = classNames(
    'tiles-wrap center-content',
    pushLeft && 'push-left'
  );

  const sectionHeader = {
    title1: 'Nuestro',
    title2: ' portafolio de servicios ',
    title3: 'se adapta',
    paragraph: 'a las distintas necesidades de cada cliente, ofreciendo soluciones integrales en la gestión del talento.'
  };

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
        <SectionHeader data={sectionHeader} className="center-content" />

          <div className={tilesClasses}>

            <div className="tiles-item reveal-from-bottom">
              <div className="tiles-item-inner services-container">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16 valores-image">
                    <Image
                      src={require('./../../assets/images/icon_serv1_2x.png')}
                      alt="Features tile icon 01"
                      width={64}
                      height={64} />
                  </div>
                  <p className="valores-title primary-7">Evaluaciones</p>
                  <p className="valores-title primary-7">psicolaborales</p>
                  <Image
                      className="line-service"
                      src={require('./../../assets/images/line_servicios_2x.png')}
                      alt="Features tile icon 02"
                      width={32}
                      height={1} />
                </div>
                <div className="services-tiles-item-content ">
                  <span className="m-0 num-service">{ConstantHelper.services_num_1} </span>
                  <span className="text-sm text-service content-text">{ConstantHelper.services_num_1_text}</span>
                </div>
                <div className="services-tiles-item-content ">
                  <span className="m-0 num-service">{ConstantHelper.services_percentage_1} </span>
                  <span className="text-sm text-service content-text">{ConstantHelper.services_percentage_1_text}</span>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom" data-reveal-delay="200">
              <div className="tiles-item-inner services-container">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16 valores-image">
                    <Image
                      src={require('./../../assets/images/icon_serv2_2x.png')}
                      alt="Features tile icon 02"
                      width={64}
                      height={64} />
                  </div>
                  <p className="valores-title primary-7">Reclutamiento</p>
                  <p className="valores-title primary-7">y selección</p>                  
                  <Image
                      className="line-service"
                      src={require('./../../assets/images/line_servicios_2x.png')}
                      alt="Features tile icon 02"
                      width={32}
                      height={1} />
                </div>
                <div className="services-tiles-item-content ">
                  <span className="m-0 num-service">{ConstantHelper.services_num_2} </span>
                  <span className="text-sm text-service content-text">{ConstantHelper.services_num_2_text}</span>
                </div>
                <div className="services-tiles-item-content ">
                  <span className="m-0 num-service">{ConstantHelper.services_percentage_2} </span>
                  <span className="text-sm text-service content-text">{ConstantHelper.services_percentage_2_text}</span>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom" data-reveal-delay="400">
              <div className="tiles-item-inner services-container">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16 valores-image">
                    <Image
                      src={require('./../../assets/images/icon_serv3_2x.png')}
                      alt="Features tile icon 03"
                      width={64}
                      height={64} />
                  </div>                  
                  <p className="valores-title primary-7">Capacitaciones</p>
                  <p className="valores-title primary-7">y terapia</p>
                  <Image
                      className="line-service"
                      src={require('./../../assets/images/line_servicios_2x.png')}
                      alt="Features tile icon 02"
                      width={32}
                      height={1} />
                </div>
                <div className="services-tiles-item-content ">
                  <span className="m-0 num-service">{ConstantHelper.services_num_3} </span>
                  <span className="text-sm text-service content-text">{ConstantHelper.services_num_3_text}</span>
                </div>
                <div className="services-tiles-item-content ">
                  <span className="m-0 num-service">{ConstantHelper.services_percentage_3} </span>
                  <span className="text-sm text-service content-text">{ConstantHelper.services_percentage_3_text}</span>
                </div>
              </div>
            </div>


          </div>
        </div>
      </div>
    </section>
  );
}

FeaturesTiles.propTypes = propTypes;
FeaturesTiles.defaultProps = defaultProps;

export default FeaturesTiles;