import React, { createRef, useRef } from 'react';

// import sections
import Hero from '../components/sections/Hero';
import FeaturesTiles from '../components/sections/FeaturesTiles';
import Valores from '../components/sections/Valores';
import FeaturesSplit from '../components/sections/FeaturesSplit';
import Services from '../components/sections/Services';
import Cta from '../components/sections/Cta';
import Testimonios from '../components/sections/Testimonios';
import Header from '../components/layout/Header';
import Footer from '../components/layout/Footer';

const Home = () => {

  const scrollWhoWeAre = useRef();
  const scrollValores = createRef();
  const scrollUs = createRef();
  const scrollServices = createRef();
  const scrollTestimonios = createRef();
  const scrollAlianzas = createRef();
  const scrollContact = createRef();



  function handleClick (value) {
    console.log(value);
    if (value === 1) {
      scrollWhoWeAre.current.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});

    }
    if (value === 2) {
      scrollValores.current.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
    }
    if (value === 3) {
      scrollUs.current.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
    }
    if (value === 4) {
      scrollServices.current.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
    }
    if (value === 5) {
      scrollAlianzas.current.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
    }
    if (value === 6) {
      scrollTestimonios.current.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
    }
    if (value === 7) {
      scrollContact.current.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
    }
  }

  return (
    <>
      <Header onTap={handleClick}/>
      <div ref={scrollWhoWeAre} ><Hero /></div>
      <div><FeaturesTiles /></div>
      <div ref={scrollValores} ><Valores /></div>
      <div ref={scrollUs} ><FeaturesSplit /></div>
      <div ref={scrollServices} ><Services /></div>
      <div ref={scrollAlianzas} ><Cta /></div>
      <div ref={scrollTestimonios} ><Testimonios /></div>
      <div ref={scrollContact} ><Footer /></div>

    </>
  );
}

export default Home;