import React from 'react';
import classNames from 'classnames';
import { SectionSplitProps } from '../../utils/SectionProps';
import UsHeader from './partials/UsHeader';
import Image from '../elements/Image';
import '../css/who_we_are.css'
import ConstantHelper from '../../global/constant_helper';

const propTypes = {
  ...SectionSplitProps.types
}

const defaultProps = {
  ...SectionSplitProps.defaults
}

const FeaturesSplit = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  ...props
}) => {

  const outerClasses = classNames(
    'features-split section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-split-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const splitClasses = classNames(
    'split-wrap',
    invertMobile && 'invert-mobile',
    invertDesktop && 'invert-desktop',
    alignTop && 'align-top'
  );

  const sectionHeader = {
    title1: 'Las',
    title2: ' socias somos ',
    title3: 'de distintas formaciones y generaciones',
    paragraph: 'lo que aporta diversidad en los enfoques para realizar nuestro trabajo.'
  };

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
          <UsHeader data={sectionHeader} className="center-content" />
          <div className={splitClasses}>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left box-us-left" data-reveal-container=".split-item">
                <div className="text-xxs primary-6 fw-600 tt-u mb-8">{ConstantHelper.associate}</div>
                <Image
                      src={require('./../../assets/images/line_socia_2x.png')}
                      alt="Features tile icon 02"
                      width={40}
                      height={1} />
                <h3 className="mt-0 mb-12 primary-4">{ConstantHelper.mily}</h3>
                <p className="m-0 primary-3 content-text-xs content-text-justify">{ConstantHelper.mily_desc}</p>
                <span className="m-0 primary-3 content-text-xs">{ConstantHelper.mily_mail}</span><span className="m-0 primary-6 content-text-xs">{ConstantHelper.email}</span>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  className="image-us-right"
                  src={require('./../../assets/images/img_Mily.jpg')}
                  alt="Features split 01"
                  width={528}
                  height={396} />
              </div>
            </div>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-right box-us-right" data-reveal-container=".split-item">
              <div className="text-xxs primary-6 fw-600 tt-u mb-8">{ConstantHelper.associate}</div>
              <Image
                    src={require('./../../assets/images/line_socia_2x.png')}
                    alt="Features tile icon 02"
                    width={40}
                    height={1} />
                <h3 className="mt-0 mb-12 primary-4">{ConstantHelper.mafer}</h3>
                <p className="m-0 primary-3 content-text-xs content-text-justify">{ConstantHelper.mafer_desc}</p>
                <span className="m-0 primary-3 content-text-xs">{ConstantHelper.mafer_mail}</span><span className="m-0 primary-6 content-text-xs">{ConstantHelper.email}</span>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  className="image-us-left"
                  src={require('./../../assets/images/img_Mafer.jpg')}
                  alt="Features split 02"
                  width={528}
                  height={396} />
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>
  );
}

FeaturesSplit.propTypes = propTypes;
FeaturesSplit.defaultProps = defaultProps;

export default FeaturesSplit;