import React from 'react';
import '../css/who_we_are.css'

//import dependencies
import ConstantHelper from '../../global/constant_helper'

const Hero = () => {

  return (
    <section className="hero section center-content ">
      <div className="container">
        <div className="hero-inner section-inner">
          <div className="hero-content">
            <h1 className="mt-0 mb-16 reveal-from-bottom text-color-primary hero-shadow" data-reveal-delay="200">
              {ConstantHelper.wwa_title_1} <span className="white-36">{ConstantHelper.wwa_title_2}</span>
            </h1>
            <div className="container-xs">
              <p className="m-0 mb-32 reveal-from-bottom text-color-primary" data-reveal-delay="400">
                {ConstantHelper.wwa_title_3}
              </p>
            </div>
          </div>
          <div className="hero-figure reveal-from-bottom illustration-element-01" data-reveal-value="20px" data-reveal-delay="800">
            
          </div>
        </div>
      </div>
    </section>
  );
}


export default Hero;